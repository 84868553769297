<template>
  <div class="order-details">
    <!--订单详情  第一模块-->
    <div class="details-first">
      <div class="details-first-userId">
        <div class="details-first-usertitle">用户ID</div>
        <div class="details-first-userId">{{ orderDetailsObj.uid }}</div>
      </div>

      <div class="details-first-phone" style="margin-left: 64px">
        <div class="details-first-phonetitle">用户手机号</div>
        <div class="details-first-phone">{{ orderDetailsObj.phone }}</div>
      </div>

      <div class="details-first-orderid" style="margin-left: 55px">
        <div class="details-first-ordertitle">订单号</div>
        <div class="details-first-ordernum">{{ orderDetailsObj.order_id }}</div>
      </div>
    </div>

    <!--订单详情  第二模块-->
    <div class="details-table">
      <el-table
        :data="orderDetailsObj.goodList"
        style="width: 100%"
        :header-cell-style="{
          background: '#FAFAFA',
        }"
      >
        <el-table-column label="商品信息" align="left" width="280">
          <template slot-scope="scope">
            <div class="commodity-div">
              <img class="commodity-div-left" :src="scope.row.image" />
              <div class="commodity-div-right">
                <div class="commodity-div-right-title">
                  {{ scope.row.name }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_spec_name"
          label="规格"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <div class="scope-name">
              {{ scope.row.goods_spec_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量" align="center">
        </el-table-column>
        <el-table-column prop="is_shouhou" label="售后状态" align="center">
          <template slot-scope="scope">
            <div
              class="isShow-div"
              :style="{
                color:
                  scope.row.is_shouhou == 1
                    ? '#FF4133'
                    : scope.row.is_shouhou == 3
                    ? '#F9B63C'
                    : '#555555',
              }"
            >
              {{
                scope.row.is_shouhou == 0
                  ? '未售后'
                  : scope.row.is_shouhou == 1
                  ? '售后中'
                  : scope.row.is_shouhou == 2
                  ? '已售后'
                  : scope.row.is_shouhou == 3
                  ? '已拒绝'
                  : ''
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价" align="center">
          <template slot-scope="scope"> ￥{{ scope.row.price }} </template>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总价" align="center">
          <template slot-scope="scope"> ￥{{ scope.row.totalPrice }} </template>
        </el-table-column>
        <el-table-column prop="getPrice" label="可获取利润" align="center">
          <template slot-scope="scope"> ￥{{ scope.row.getPrice }} </template>
        </el-table-column>
        <el-table-column prop="status" label="利润状态" align="center">
          <template slot-scope="scope">
            <div
              class="profitStatus-div"
              :style="{
                color: scope.row.status == 'ENTER' ? '#f9b63c' : '#666666',
              }"
            >
              {{
                scope.row.status == 'ENTER'
                  ? '已获取'
                  : scope.row.status == 'WAIT'
                  ? '锁定中'
                  : scope.row.status == 'PAST'
                  ? ''
                  : ''
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['orderDetailsObj'],
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.order-details {
  .details-first {
    width: 100%;
    height: 62px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 26px;
    box-sizing: border-box;
    margin-top: 20px;

    .details-first-userId,
    .details-first-phone,
    .details-first-orderid {
      display: flex;
      .details-first-usertitle,
      .details-first-phonetitle,
      .details-first-ordertitle {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #555555;
      }

      .details-first-userId,
      .details-first-phone,
      .details-first-ordernum {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-left: 4px;
      }
    }
  }

  .details-table {
    margin-top: 10px;
    padding: 24px;
    box-sizing: border-box;
    background: #ffffff;

    /deep/ .el-table th.el-table__cell > .cell {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333;
    }

    /deep/ .el-table {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #555555;
    }

    .scope-name {
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .commodity-div {
      display: flex;
      align-items: center;
      .commodity-div-left {
        width: 40px;
        height: 40px;
      }
      .commodity-div-right {
        margin-left: 10px;
        .commodity-div-right-title {
          color: black;
          font-size: 14px;
          font-weight: 400;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .commodity-div-right-text {
          margin-top: 31px;
          color: black;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
