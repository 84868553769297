var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-details"},[_c('div',{staticClass:"details-first"},[_c('div',{staticClass:"details-first-userId"},[_c('div',{staticClass:"details-first-usertitle"},[_vm._v("用户ID")]),_c('div',{staticClass:"details-first-userId"},[_vm._v(_vm._s(_vm.orderDetailsObj.uid))])]),_c('div',{staticClass:"details-first-phone",staticStyle:{"margin-left":"64px"}},[_c('div',{staticClass:"details-first-phonetitle"},[_vm._v("用户手机号")]),_c('div',{staticClass:"details-first-phone"},[_vm._v(_vm._s(_vm.orderDetailsObj.phone))])]),_c('div',{staticClass:"details-first-orderid",staticStyle:{"margin-left":"55px"}},[_c('div',{staticClass:"details-first-ordertitle"},[_vm._v("订单号")]),_c('div',{staticClass:"details-first-ordernum"},[_vm._v(_vm._s(_vm.orderDetailsObj.order_id))])])]),_c('div',{staticClass:"details-table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.orderDetailsObj.goodList,"header-cell-style":{
        background: '#FAFAFA',
      }}},[_c('el-table-column',{attrs:{"label":"商品信息","align":"left","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"commodity-div"},[_c('img',{staticClass:"commodity-div-left",attrs:{"src":scope.row.image}}),_c('div',{staticClass:"commodity-div-right"},[_c('div',{staticClass:"commodity-div-right-title"},[_vm._v(" "+_vm._s(scope.row.name)+" ")])])])]}}])}),_c('el-table-column',{attrs:{"prop":"goods_spec_name","label":"规格","align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"scope-name"},[_vm._v(" "+_vm._s(scope.row.goods_spec_name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"num","label":"数量","align":"center"}}),_c('el-table-column',{attrs:{"prop":"is_shouhou","label":"售后状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"isShow-div",style:({
              color:
                scope.row.is_shouhou == 1
                  ? '#FF4133'
                  : scope.row.is_shouhou == 3
                  ? '#F9B63C'
                  : '#555555',
            })},[_vm._v(" "+_vm._s(scope.row.is_shouhou == 0 ? '未售后' : scope.row.is_shouhou == 1 ? '售后中' : scope.row.is_shouhou == 2 ? '已售后' : scope.row.is_shouhou == 3 ? '已拒绝' : '')+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"price","label":"单价","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" ￥"+_vm._s(scope.row.price)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"totalPrice","label":"总价","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" ￥"+_vm._s(scope.row.totalPrice)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"getPrice","label":"可获取利润","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" ￥"+_vm._s(scope.row.getPrice)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"利润状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"profitStatus-div",style:({
              color: scope.row.status == 'ENTER' ? '#f9b63c' : '#666666',
            })},[_vm._v(" "+_vm._s(scope.row.status == 'ENTER' ? '已获取' : scope.row.status == 'WAIT' ? '锁定中' : scope.row.status == 'PAST' ? '' : '')+" ")])]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }